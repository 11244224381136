.Video{

    .body{
        background-color: #fff;
        padding: 20px;
    }


    .preview{
        position: relative;
    }
    .media:hover .video-overlay{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 210px;
        height: 118px;
        display: block;
        background-color: rgba(255,255,255,0.5);
        z-index: 999;
        transition: .5s ease;

    }    
    .media .video-overlay .playnow{
        display:none;
    }      
    .media:hover .video-overlay .playnow{
        display:inline-block;
        margin-top: 50px;
    } 

    .no-lib-box{
        padding: 100px;
    }
    
    .no-lib{
        width: 100px;
        height: 100px;
        text-align: center;
        display: block;
        margin:auto;

    }
    .no-lib-text{
        text-align: center;
    }
}


.packageSelect{

    h2.title{
        font-size: 1.41em;
        font-weight: 700;
        margin: 0.5rem 0;
        text-transform: capitalize;
    }
    p.info{
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        font-weight: 400;
    }    
    p.price-intro{
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        font-weight: 400;
        margin-block-end: 0px;
    }
    .currency{
        position: relative;
        font-size: 0.8em;
        color: rgba(0,0,0,1);
        font-weight: 600;
        top: -10px;
        padding-right: 5px;
    }
    p.price{
        font-size: 2em;
        color: rgba(0,0,0,1);
        font-weight: 700;
        margin-block-start: 10px;
        margin-block-end: 10px;
        position: relative;
    }
    .month{
        position: relative;
        font-size: 0.5em;
        color: rgba(0,0,0,1);
        font-weight: 300;
        margin-left: 2px;
    }
    .select-button{
        color:#fff;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    p.includes{
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        font-weight: 600;
        margin-block-end: 0px;
    }
    .properties{
        line-height: 20px;
        margin-bottom: 8px;
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        font-weight: 400;

    }
}

.packageSelect.primary{

    h2.title{
        color: #ffb404;
    }
    .select-button{
        background-color: #ffb404;
    }
}

.packageSelect.secondary{

    h2.title{
        color: #25c6fe;
    }
    .select-button{
        background-color: #25c6fe;
    }
}

.video-statistics{
    .header{
        text-transform: capitalize;
    }
    .MuiCardHeader-title{
        line-height: 35px;

    }
    .MuiCardHeader-subheader{
        font-size: 0.8em;
    }
    .allowance{
        font-size: 2em;
        color: rgba(0,0,0,1);
        font-weight: 700;
        margin-block-start: 10px;
        margin-block-end: 10px;
        position: relative;
    }
    .of{
        position: relative;
        font-size: 0.5em;
        color: rgba(0,0,0,1);
        font-weight: 300;
        margin-left: 2px;
    }
}

.section{
    h2.title{
        font-size: 1.41em;
        font-weight: 700;
        margin: 0.5rem 0;
        text-transform: capitalize;
    }
    p.info{
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        font-weight: 400;
    } 
}

.nav.MuiAppBar-colorPrimary{
        background-color: #fff;
        box-shadow: none;
        border: 1px solid rgba(0,0,0,0.12);
}

.video-folder{
    text-align: center;
    border: none;
    .header{
        padding-bottom: 5px;
    }
    .header .MuiTypography-h5{
        font-size: 1em;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 300;
    }
    .lib-content{
        padding: 0px;
    }
    .ico{
        font-size: 3em;
    }
    h3.title{
        font-size: 0.8em;
        margin-block-start: 0em;
        margin-block-end: 0em;
        font-weight: 300;
    }
}


.video-element{
    text-align: center;
    border: none;
    padding: 5px;
    .header{
        padding-bottom: 5px;
    }
    .header .MuiTypography-h5{
        font-size: 1em;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 300;
    }
    .lib-content{
        padding: 0px;
    }
    .lib-content .preview{
        overflow: hidden;
        width: 100%;
    }

    .lib-content .preview img{
        overflow: hidden;
    }

    .lib-content .preview{
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        text-align: center;
    }

    p.info{
        text-align: left;
        font-size: 0.9em;
        color: rgba(0,0,0,0.5);
        font-weight: 400;
        padding: 3px 14px;
        margin-block-start: 0em;
        margin-block-end: 0em;        
    }
    p.info span{
        font-weight: 600;
   
    }

    .preview{
        position: relative;
    }
    .lib-content:hover .video-overlay{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 184px;
        display: block;
        background-color: rgba(255,255,255,0.5);
        z-index: 999;
        transition: .5s ease;

    }    
    .lib-content .video-overlay .playnow{
        display:none;
    }      
    .lib-content:hover .video-overlay .playnow{
        position: absolute;
        left: 35%;
        top: 35%;
        display: inline-block;
    } 


}

.app-title{
    font-weight: 300;
    color: rgba(0,0,0,1);
    font-size: 2em !important;
    padding-left: 10px;
}

.video-box{
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

#expiry{
    font-size: 1.17em;
    font-weight: bold;
}

#pass-select{

    line-height: 30px;
}

 

.alert{
    line-height: 5em;
    font-size: 1em;
    position: relative;
}

.alert span{
    margin-right: 10px;
    color: #ef5350;
    position: relative;
    top: 30px;
}

.alert span svg{
    font-size: 4em;

}

.drop-area{
    position: relative;
    border: 1px dashed rgba(0,0,0,0.5);
    display: block;
}

.file-drop {
    /* relatively position the container bc the contents are absolute */
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 300px;
  }

  .drop-area .label{
    position: absolute;
    top: 50%;
    font-size: 2em;
    display: block;
    width: 100%;
    text-align: center;
    color:#000;
    font-weight: 300;

  }

  .hidden{
    visibility: hidden;
  }

  .drop-area .label > .small{
    font-size: 0.5em;
    margin-top: 15px;
  }

  .drop-area .uploaderror{
    color: #ef5350;
    font-size: 0.5em;
    line-height: 30px;
    margin-top: 10px;
  }

  .drop-area .uploaderror span{
    margin-right: 10px;
    color: #ef5350;
    position: relative;
    top: 5px; 
 }

 .drop-area .uploaderror span svg{
    font-size: 1.5em;

}
  
  .file-drop > .file-drop-target {
    /* basic styles */
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 2px;
  
    /* horizontally and vertically center all content */
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
  
    flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
  
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
  
    justify-content: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
  
    align-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
  
    text-align: center;
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-frame {
    /* overlay a black mask when dragging over the frame */
    border: none;
    background-color: rgba(0, 0, 0, 0.65);
    box-shadow: none;
    z-index: 50;
    opacity: 1;
  
    /* typography */
    color: white;
  }
  
  .file-drop > .file-drop-target.file-drop-dragging-over-target {
    /* turn stuff orange when we are dragging over the target */
    color: #2196f3  ;
    box-shadow: 0 0 13px 3px #2196f3;
  }


.upload-progress{
    font-size: 2em;
    display: block;
    width: 100%;
    text-align: center;
    text-transform: capitalize;
    margin-top: 10px;
  }

